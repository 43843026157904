/* eslint-disable import/prefer-default-export */
import React, { StrictMode } from "react";
import ReactDOM from 'react-dom/client';
import Layout from "./src/app/layout/Layout";

export const wrapPageElement = ({ element, props, ...others }) => {
  return (
    <StrictMode>
      <Layout {...props}>{element}</Layout>
    </StrictMode>
  );
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}